import { Link } from "react-router-dom";
import Container from "../components/Container";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";

const Home = ()=>{
    const {token} = useSelector(state=>state.credentials);
    
    return (
        <div className="home">
            <Container>
                <div className="start">
                    <motion.div style={{overflow: "hidden"}} 
                        whileInView="enter"
                        viewport={{once: true}}
                    >
                        <motion.h1
                            initial={{y: 300, opacity: 0}}
                            variants={{enter:{y:0, opacity: .7}}}
                            transition={{duration: 2}}
                        >
                            Explorez un futur prometteur pour vos projets avec Maapix, <br/>votre assistant stratégique !
                        </motion.h1>
                    </motion.div>

                    <motion.div 
                        className="logo-wrapper"
                        initial={{y: 100, opacity:0}}
                        whileInView={{y: 0, opacity: 1}}
                        transition={{delay: .2, duration: .2}}
                        viewport={{once: true}}
                    >
                        <div className="logo"></div>
                        <h3>Outil d’analyse de potentiel basé sur l’IA</h3>

                    </motion.div>

                    <motion.div
                        initial={{y: 100, opacity:0}}
                        whileInView={{y: 0, opacity: 1}}
                        transition={{delay: .2, duration: .4}}
                        viewport={{once: true}}
                        style={{margin: 20}}
                    >

                        <Link to="/dashboard" className="btn">Commencer</Link>

                        
                    </motion.div>

                    <motion.div 
                        className="link"
                        initial={{y: 100, opacity:0}}
                        animate={{y: 0, opacity: 1}}
                        transition={{ duration: .5}}
                        viewport={{once: true}}
                    >
                        <Link to={token?"/auth/logout": "/auth/login"}>{token? "Se deconnecter": "Se connecter"}</Link> /
                        <Link to={token? "/dashboard":"/auth/register"}> {token?"Mes projets":"S'enrégistrer"}</Link>
                    </motion.div>
                </div>
                <div className="wrapper">
                    <motion.div className="features"
                       
                    >
                        {FEATURES.map((data, k)=>(
                            <motion.div 
                                className="feature" key={k}
                                style={{overflow: "hidden"}}
                                initial="free"
                                whileInView="enter"
                                // viewport={{once: true}}
                                variants={{
                                    free:{
                                        
                                        scale: .95
                                    },
                                    enter:{
                                        scale: 1,
                                    }
                                }}
                                transition={{type: "spring", stiffness: 800}}
                            >
                                <div className="body">
                                    <span className={"img " + data.name}></span>
                                    <h3>{data.title}</h3>
                                    <p>
                                        {data.desc}
                                    </p>
                                </div>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
                <motion.div 
                    style={{display: "flex", justifyContent: "center", padding: "1em"}}
                    // initial={{y: 100, opacity:0}}
                    // whileInView={{y: 0, opacity: 1}}
                    // transition={{ duration: .5}}
                    // viewport={{once: true}}
                >
                    <Link to="/about" className="btn" >En savoir plus</Link>
                </motion.div>
            </Container>
        </div>
    )
}

const FEATURES = [
    {
        title: "Speech de Présentation",
        name: "speech",
        desc: "Maapix, votre partenaire pour créer un discours percutant qui capte l'attention de votre auditoire. \n Mettez en valeur les atouts de votre projet et suscitez l'enthousiasme des investisseurs, clients potentiels et partenaires."
    },
    {
        title: "Plan Marketing",
        name: "marketing",
        desc: "Maapix analyse en profondeur votre marché cible et vos concurrents pour élaborer un plan marketing sur mesure. Atteignez vos objectifs de croissance grâce à des stratégies efficaces et des actions ciblées."
    },
    {
        title: "Analyse de Potentiel",
        name: "analyse",
        desc: "Découvrez le potentiel réel de votre projet grâce à nos analyses approfondies. Évaluez les opportunités du marché, identifiez les risques potentiels, évaluez la viabilité opérationnelle et prévoyez la rentabilité future."
    },
    {
        title: "Possibilité d'Investissement",
        name: "finance",
        desc: "Maapix est en partenariat avec des structures de levée de fonds renommées. Soumettez votre projet pour évaluer son potentiel d'investissement et accéder à des opportunités de financement."
    }
]

export default Home;