import { useCallback, useState } from "react"
import Container from "../components/Container"
import Menu from "../components/Menu"
import _, { range } from "lodash"
import { Button, Input, MultipleInput } from "../components/tools/formTools"
import {motion} from "framer-motion"
import { useDataReducer } from "../utils/useDataReducer"
import { useDispatch } from "react-redux"
import { post } from "../store/action/request"
import { PROJECTS } from "../store/apiUrls"
import { useNavigate } from "react-router-dom"
import { Detail } from "./Projet"
import { addNotification } from "../store/action/notification"

const max = 6;

const NewProject = ()=>{
    const [current, setCurrent] = useState(1);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState(range(2, max+1));
    const {data, saveAs} = useDataReducer({});
    const [display, setDisplay] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const rm = useCallback((n, from, reset=true)=>{
        const f = from === "left"? setLeft : setRight;
        reset===true && setRight(v=>[...v, current]);
        setDisplay(false);
        
        setTimeout(()=>{
            f(v=>{
                _.remove(v, i=>i===n);
                return [...v];
            });
            setCurrent(n);
        }, 500)
    }, [current])

    const add = useCallback((n, from)=>{
        const f = from === "left"? setLeft : setRight;

        f(v=>[...v, n]);
    }, [])

    const submit = useCallback(e=>{
        e.preventDefault();
        
        if(current < max ){
            add(current, "left");
            rm(right.sort()[0],"right", false);
          
        }else{
            // add(current, "left");
            setDisplay(true);
        }
    }, [current, add, rm, right])

    const save = useCallback(()=>{
        dispatch(post({
            url: PROJECTS,
            data,
            auth: true,
            success: ()=>{
                navigate("/dashboard");
            },
            key: "new-project",
            error: ()=>dispatch(addNotification({message: "Une erreur s'est produite, verifier que vous aviez correctement remplir le formulaire et votre connexion", title: "Erreur de soumission"}))
        }));
    }, [dispatch, data, navigate])

    return (
        <div className="new-project viewport-wrapper">
            <Container requiredAuth={true}>
                <Menu/>

                <div className="viewport">
                    <div className="form">
                        {/* <AnimatePresence> */}
                        {!display?(
                            <motion.form key={current} onSubmit={submit} layoutId={current}>
                                <div className="title">
                                    <div className="badge">{current}</div>
                                    <h2>{FORM[current].title}</h2>
                                </div>
                                <div>
                                    {FORM[current].fields(data, saveAs)}
                                </div>
                                <Button disabled={current===max && right.length !== 0}>Suivant</Button>
                            </motion.form>

                        ):(
                            <div className="display" style={{width: "100%", paddingBottom: 50}}>
                                <Detail project={data} />

                                <div className="send" style={{position: "fixed", bottom: 0}}>
                                    <Button pk="new-project" onClick={save}>Enrégistrer</Button>
                                </div>
                            </div>
                        )}
                        {/* </AnimatePresence> */}
                    </div>

                    {!display&&(
                        <div className="step-card">
                            <div className="left" style={{width: (left.length) * 35}}>

                                {left.length > 0 && left.sort().map((n, i)=>(
                                    <motion.div  
                                        layoutId={n}
                                        key={n}
                                        className="card" 
                                        onClick={()=>rm(n, "left")}
                                        style={{zIndex: (left.length-i), right: (left.length-i -1) * 35 + 2, height: 30 + (i)*3}}
                                    >
                                        <div  className="badge">{n}</div>
                                    </motion.div>
                                ))}
                            </div>

                            <div className="right" style={{width: right.length * 35}}>
                                {right.sort().map((n, i)=>(
                                    <motion.div 
                                        layoutId={n}
                                        key={n}
                                        className="card" 
                                        onClick={()=>rm(n, "right")}
                                        style={{zIndex: i, left: (i) * 35 + 2, height: 30 + (right.length - i-1)*3}}
                                    >
                                        <div  className="badge">{n}</div>
                                    </motion.div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </Container>
        </div>
    )
}

const extract = set=>e=>set(e.target.value);

const FORM = {
    "1": {
        title: "Description du projet",
        fields: (data, saveAs)=>(
            <>
                <Input label="Nom du projet"  placeholder="Quel est le nom de votre projet?" required value={data.detail?.name || ""} onChange={extract(saveAs("detail.name"))} />
                <Input type="textarea" label="Brève description du projet" placeholder="Décrivez brièvement votre projet en quelques phrases" required value={data.detail?.desc || ""} onChange={extract(saveAs("detail.desc"))}/>
                <Input label="Secteur d'activité"  placeholder="Précisez le secteur dans lequel votre projet opère" required value={data.detail?.sector || ""} onChange={extract(saveAs("detail.sector"))}/>
                <MultipleInput label="Objectifs du projet" placeholder="Quels sont vos principaux objectifs pour ce projet ?" onChange={saveAs("detail.aims")} initial={data.detail?.aims} />
            </>
        )
    },
    "2": {
        title: "Marché cible",
        fields: (data, saveAs)=>(
            <>
                <Input type="textarea" label="Description de la clientèle cible" placeholder="Expliquez le profil de vos clients potentiels" required value={data.market?.desc || ""} onChange={extract(saveAs("market.desc"))} />
                <Input label="Taille du marché"  placeholder="Estimez la taille du marché que vous visez" required value={data.market?.size || ""} onChange={extract(saveAs("market.size"))}/>
                <MultipleInput label="Tendances et opportunités du marché" placeholder="Quelles sont les tendances et les opportunités pertinentes dans votre marché ?" onChange={saveAs("market.proprieties")} initial={data.market?.proprieties}/>

            </>
        )
    },
    "3": {
        title: "Modèle économique",
        fields: (data, saveAs)=>(
            <>
                <Input type="textarea" label="Description du modèle économique" placeholder="Décrivez brièvement votre modèle économique actuel ou prévu" required value={data.economy?.desc || ""} onChange={extract(saveAs("economy.desc"))}/>
                <Input label="Sources de revenus"  placeholder="Quelles sont les principales sources de revenus pour votre projet ?" required value={data.economy?.source || ""} onChange={extract(saveAs("economy.source"))}/>
                <Input type="textarea" label="Coûts et dépenses" placeholder="Identifiez les principaux coûts et dépenses associés à votre projet" required value={data.economy?.outlay || ""} onChange={extract(saveAs("economy.outlay"))}/>
            </>
        )
    },
    "4": {
        title: "Avantages concurrentiels",
        fields: (data, saveAs)=>(
            <>
                <MultipleInput label="Principaux avantages et différenciateurs" placeholder="Qu'est-ce qui distingue votre projet de la concurrence ?" onChange={saveAs("advantage.advantage")} initial={data.advantage?.advantage}/>
            </>
        )
    },
    "5": {
        title: "État d'avancement du projet",
        fields: (data, saveAs)=>(
            <>
                <Input type="textarea" label="Étape actuelle du projet" placeholder="Où en êtes-vous dans le développement de votre projet ?" required value={data.state?.current || ""} onChange={extract(saveAs("state.current"))} />
                <MultipleInput label="Réalisations ou jalons déjà atteints" placeholder="Veuillez préciser les réalisations importantes ou les jalons que vous avez déjà atteints" onChange={saveAs("state.realizations")} initial={data.state?.realizations}/>
            </>
        )
    },
    "6": {
        title: "Autres informations pertinentes",
        fields: (data, saveAs)=>(
            <>
                <MultipleInput label="Tout autre élément à prendre en compte" placeholder="Si vous avez des informations supplémentaires pertinentes, veuillez les mentionner" onChange={saveAs("other.important")} initial={data.other?.important} />
            </>
        )
    },
    
}

export default NewProject;