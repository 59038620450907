
import { useCallback, useReducer, } from "react"
import { useDispatch } from "react-redux"
import { setErrors } from "../store/action/alert"
import { notNull } from "./validators"

const _ = require("lodash")

const setData = (state, payload) =>{
    let array = payload.key.split(".");

   _.set(state, array, payload.value);
    
    switch (payload.type){
        case "reset":
            return {}
        default:
            return {...state}
    }
}


export const useDataReducer = ({init={}, required=[], validators={}})=>{
    const [data, dispatcher] = useReducer(setData, init)
    const dispatch = useDispatch()
    
    const saveAs = useCallback((key)=>{
        const wrapper = (value)=>{
            dispatcher({key, value})
        }
        return wrapper
    }, [dispatcher])

    const reset = useCallback(()=>{
        dispatcher({type: "reset"})
    }, [dispatcher])

    const validate = useCallback(()=>{
        const allErrors = {}
        let keys = _.union(_.keys(data), required)
        
        keys.forEach(key=>{
            const all = validators.all || []
            const funcs = validators[key] || []
            const allFincs = [...all, ...funcs]
            if (required.includes(key)) allFincs.push(notNull)
            
            let errors = []

            if(allFincs.length>0){
                allFincs.forEach(f=>{
                    const err = f(data[key])
                    if( typeof(err) === "object") errors = [...errors, ...err]
                    else if (typeof(err) === "string") errors = [...errors, err]
                })
            }
            if(errors.length > 0) allErrors[key] = errors

        })

        if(_.isEmpty(allErrors)) return true

        dispatch(setErrors(allErrors))
        return false
        
        
    }, [validators, data, required, dispatch])

    return {data, saveAs, validate, reset}
}