import { Link } from "react-router-dom";
import Container from "../components/Container"
import Menu from "../components/Menu";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { PROFILE } from "../store/apiUrls";
import { get } from "../store/action/request";
import { setUser } from "../store/action/user";



const Profile = ()=>{

    const user = useSelector(state=>state.user);
    const dispatch = useDispatch();
    const {isLoading} = useSelector(state=>state.request);

    useEffect(()=>{
        if(!user.email) dispatch(get({
            url: PROFILE,
            auth: true,
            success: data=>dispatch(setUser(data)),
            key: "profile"
        }))
    }, [user, dispatch])

    return (
        <div className="profile viewport-wrapper">
            <Container requiredAuth={true}>
                <Menu/>
                <div className="viewport">
                    {userIcon}
                    
                    <div className="info">
                        <strong>{user.full_name}</strong>
                        <i>{user.email}</i>
                        <span>{user.phone_number}</span>
                    </div>

                    <strong>
                        <Link to="/auth/change-password?back=/dashboard/profile">Changer votre mot de passe</Link>
                    </strong>
                </div>
                {isLoading["profile"] && (
                    <div className="layer">
                        <div className="loading big"></div>
                    </div>

                )}
            </Container>
        </div>
    )
}

const userIcon = (
    <svg width="120" height="120" viewBox="0 0 120 110" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="user-profile_svgrepo.com">
<g id="Group">
<g id="Group_2">
<path id="Vector" d="M60.0002 8.6625C41.9252 8.6625 27.2739 22.2097 27.2739 38.9228C27.2739 55.6359 41.9252 69.1831 60.0002 69.1831C78.0752 69.1831 92.7264 55.6359 92.7264 38.9228C92.7264 22.2097 78.0752 8.6625 60.0002 8.6625ZM60.0002 65.7456C44.0214 65.7456 31.0239 53.7144 31.0239 38.9228C31.0239 24.1312 44.0214 12.1 60.0002 12.1C75.9789 12.1 88.9764 24.1312 88.9764 38.9228C88.9764 53.7109 75.9789 65.7456 60.0002 65.7456Z" fill="#FFDAB9"/>
</g>
</g>
<g id="Group_3">
<g id="Group_4">
<path id="Vector_2" d="M60.2998 36.1384C63.9884 36.1384 66.9786 33.3744 66.9786 29.9647C66.9786 26.555 63.9884 23.7909 60.2998 23.7909C56.6113 23.7909 53.6211 26.555 53.6211 29.9647C53.6211 33.3744 56.6113 36.1384 60.2998 36.1384Z" fill="#006400"/>
</g>
</g>
<g id="Group_5">
<g id="Group_6">
<path id="Vector_3" d="M67.6988 40.4009L60.3188 45.5469L52.9388 40.4009C43.8526 42.9206 43.9688 51.425 43.9688 51.425H60.3226H76.6688C76.6688 51.425 76.7813 42.9206 67.6988 40.4009Z" fill="#006400"/>
</g>
</g>
<g id="Group_7">
<g id="Group_8">
<path id="Vector_4" d="M92.7263 82.775H27.27V86.2125H92.7263V82.775Z" fill="#FFDAB9"/>
</g>
</g>
<g id="Group_9">
<g id="Group_10">
<path id="Vector_5" d="M120 97.9H0V101.337H120V97.9Z" fill="#006400"/>
</g>
</g>
</g>
</svg>
)

export default Profile;