import { useDispatch } from "react-redux";
import Notification from "./Notification";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loadCredentials } from "../store/action/credential";


const Container = ({children, requiredAuth=false})=>{

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(()=>{

        if(!requiredAuth) return

        dispatch(loadCredentials(c=>{
            const {token} = c;
            if(!token){
                navigate("/auth/login");
            }
        }))
    }, [ dispatch, requiredAuth, navigate])

    return (
        <div className="container page-wrapper">

            <div className="page-body">
                {children}
            </div>
            <Notification/>
        </div>
    )
}

export default Container;
