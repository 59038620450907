import { Link } from "react-router-dom";
import { animate, motion, stagger } from "framer-motion";
import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadCredentials } from "../store/action/credential";
import { PROFILE } from "../store/apiUrls";
import { setUser } from "../store/action/user";
import { get } from "../store/action/request";


const Menu = ()=>{
    const [hide, setHide] = useState(true);
    const [small, setSmall] = useState(false);
    const [opacity, setOpacity] = useState(0);
    const user = useSelector(state=>state.user);
    const {token} = useSelector(state=>state.credentials);
    const dispatch = useDispatch();

    useLayoutEffect(()=>{
        function f(){
            const sm = window.innerWidth < 700;
            setSmall(sm);
            setHide(sm);
        }
        f();

        window.addEventListener("resize", f);

    }, [])

    useEffect(()=>{
        if(!token) dispatch(loadCredentials())
        if(token && !user.email) dispatch(get({
            url: PROFILE,
            auth: true,
            success: data=>dispatch(setUser(data)),
            key: "profile"
        }))
        
    }, [token, user, dispatch])

    useLayoutEffect(()=>{
        const {width} = document.querySelector(".menu").getBoundingClientRect();

        const seq = hide?[
            [
                "nav", 
                // {width: 0}, 
                {y: 100, opacity: 0, scale: .7},
                {duration: .3, delay: stagger(.2, {from: "last"})}
            ],
            [document.querySelector("div.menu-wrapper"), {width: 0}, {duration: .2}],
            [document.querySelector("div.menu-box"), {height: "50px", width: "50px"}, {duration: 0.00001}],

        ]:[
            [
                document.querySelector("div.menu-wrapper"), 
                {width: "100%"}, 
                {duration: .3, ease: [0.08, 0.65, 0.53, 0.96]}
            ],
            [document.querySelector("div.menu-box"), {height: window.innerHeight+"px", width:(width+50)+ "px"}, { duration: .01, at:"<"}],
            [
                "nav", 
                // {width: "100%"}, 
                {y: 0, opacity: 1, scale: 1},
                {duration: .5, delay: stagger(.2), type: "spring", stifness: 500, at: "<"}
            ],
        ]

        let seqs = [
            [
                "path.top",
                { d: !hide ? "M 3 16.5 L 17 2.5" : "M 2 2.5 L 20 2.5" },
                { at: "<" }
            ],
            ["path.middle", { opacity: !hide ? 0 : 1 }, { at: "<" }],
            [
                "path.bottom",
                { d: !hide ? "M 3 2.5 L 17 16.346" : "M 2 16.346 L 20 16.346" },
                { at: "<" }
            ],
        ]

        seqs.push(...seq)

        const anim = animate(seqs);
        anim.then(()=>setOpacity(1))

        // anim.complete(()=>console.log("end"))
    }, [hide, small])

    
    return (
        <div 
            className="menu-box" 
            style={{
                position: small? "absolute": null,  
                zIndex: 1000, 
                display: "flex", 
                opacity: small? opacity: 1
            }}
        >
            <div 
                className="menu-wrapper" 
                style={{
                    overflow: "hidden", 
                    // width: 0
                }} 
                // initial={{width: 0}} 
                // animate={{width: hide? 0: null}}
            >
                <motion.div className="menu">
                    <div className="logo-wrapper">
                        <div className="logo"></div>
                    </div>

                    <nav>
                        <NavLink to="/">Accueil</NavLink>
                        <NavLink to="/dashboard">Mes projets</NavLink>

                        <NavLink to="/dashboard/submitted">Autres Projets</NavLink>
                        <NavLink to="/dashboard/new-project">Nouveau projet</NavLink>
                    </nav>

                    <nav>
                        <NavLink to="/dashboard/profile">Mon Profile</NavLink>
                        <NavLink to={`/about?back=${window.location.pathname}`}>À propos</NavLink>
                        <NavLink to="/auth/logout">Se déconnecter</NavLink>
                    </nav>
                </motion.div>
            </div>
            
            {small && (
                <div onClick={()=>setHide(v=>!v)} className="menu-icon">
                    <svg width="23" height="18" viewBox="0 0 23 18" fill="currentcolor">
                        <Path
                            d="M 2 2.5 L 20 2.5"
                            className="top"
                            variants={{
                                closed: { d: "M 2 2.5 L 20 2.5" },
                                open: { d: "M 3 16.5 L 17 2.5" }
                            }}
                        />
                        <Path d="M 2 9.423 L 20 9.423" opacity="1" className="middle" />
                        <Path
                            d="M 2 16.346 L 20 16.346"
                                className="bottom"
                                variants={{
                                closed: { d: "M 2 16.346 L 20 16.346" },
                                open: { d: "M 3 2.5 L 17 16.346" }
                            }}
                        />
                    </svg>
                </div>
            )}
        </div>
    )
}

const NavLink = ({to, children}) =>{
    return (
        <div className="nav-link">
            {/* {window.href} */}
            <Link to={to}>{children}</Link>
            {window.location.pathname === to && (
                <motion.div layoutId="nav-back" className="back"></motion.div>
            )}
        </div>
    )

}

const Path = (props) => (
	<path
		fill="transparent"
		strokeWidth="3"
		stroke="rgb(0, 0, 102)"
		strokeLinecap="round"
		{...props}
	/>
  );

export default Menu;
