import { setErrors } from "../store/action/alert"

let _ = require("lodash")
export function notNull(value){

    if(value) return
    return "Ce champs ne peut pas être vide"

} 

export function confirmEq(dispatch, val1, val2, msg){
    if(val1 !== val2) {
        dispatch(setErrors({confirm: [ msg || "Veuillez confirmer"]}))
        return false
    }
    return true
}

export function email(value){

    if(!value || _.endsWith(value, "@gmail.com")) return
    else return "Entrer un address email valide"

} 

