import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Home";
import "../styles/index.scss";
import { ChangePass, ForgotPass, Login, Logout, Register, ResetPass } from "./Auth";
import About from "./About";
import Dashboard from "./Dashbord";
import Profile from "./Profile";
import NewProject from "./NewProject";
import Project from "./Projet";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { addNotification } from "../store/action/notification";
import { loadCredentials } from "../store/action/credential";



const Pages = ()=>{
	const {errors: {detail}} = useSelector(state=>state.alert);
	const dispatch = useDispatch();

	useEffect(()=>{
		detail && dispatch(addNotification({message: detail}, 5000));
	}, [detail, dispatch])

	useEffect(()=>{
		dispatch(loadCredentials());
	}, [dispatch])

    return (
        <BrowserRouter>
			<Routes>
				<Route path="" element={<Home/>} />
				<Route path="/about" element={<About/>} />

				<Route path="/dashboard/">
					<Route path="" element={<Dashboard/>} />
					<Route path="submitted" element={<Dashboard submitted={true}/>} />
					<Route path="profile" element={<Profile/>} />
					<Route path="new-project" element={<NewProject/>}/>
					<Route path="project/:pk" element={<Project/>}/>

				</Route>
				<Route path="/auth/">
					<Route path="login" element={<Login/>} />
					<Route path="logout" element={<Logout/>} />
					<Route path="register" element={<Register/>} />
					<Route path="forgot-password" element={<ForgotPass/>} />
					<Route path="reset-password" element={<ResetPass/>} />
					<Route path="change-password" element={<ChangePass/>} />
				</Route>

			</Routes>
		</BrowserRouter>
    )
}

export default Pages;
