import { useCallback, useEffect, useState } from "react";
import Container from "../components/Container";
import {LayoutGroup, motion} from "framer-motion";
import { useNavigate, useSearchParams } from "react-router-dom";

const About = ()=>{
    const [open, setOpen] = useState();
    const [search] = useSearchParams();
    const navigate = useNavigate();

    useEffect(()=>{
        const to = search.get("of");
        if(!to) return;
        
        let elm = document.querySelector("#"+to)
        if(!elm) return;
        setOpen(to);
        let container = document.querySelector(".page-wrapper")
        
        container.scrollTo({top: elm.offsetTop - 10, behavior: 'smooth',})
        
    }, [search])

    const toggleOpen = useCallback(name=>{
        setOpen(c=>c===name? null: name)
    }, [])

    return (
        <div className="about">
            <Container>
                <div className="page-content">
                    <div className="h1">
                        <span onClick={()=>navigate(search.get("back") || "/")}>
                            <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="back-interface-ui-ux_svgrepo.com">
                                    <path id="Vector" d="M23.926 22.512C23.8186 22.5122 23.7128 22.487 23.6171 22.4384C23.5214 22.3897 23.4386 22.3191 23.3755 22.2323L22.2308 20.6611C21.625 19.8306 20.8317 19.1549 19.9154 18.689C18.9991 18.2231 17.9857 17.9802 16.9578 17.98H16.049V18.9478C16.049 19.8521 15.5424 20.6551 14.7258 21.0437C14.3305 21.2347 13.8887 21.3086 13.4527 21.2566C13.0168 21.2047 12.6048 21.029 12.2654 20.7503L7.25251 16.6825C6.98428 16.4649 6.768 16.1902 6.61944 15.8783C6.47089 15.5665 6.3938 15.2254 6.3938 14.88C6.3938 14.5346 6.47089 14.1935 6.61944 13.8817C6.768 13.5698 6.98428 13.2951 7.25251 13.0775L12.2654 9.00966C12.6048 8.73103 13.0168 8.55534 13.4527 8.50336C13.8887 8.45137 14.3305 8.52526 14.7258 8.71629C15.5424 9.10493 16.049 9.9079 16.049 10.8122V12.2987L18.4836 12.5951C20.1726 12.8018 21.7276 13.6193 22.8555 14.8934C23.9833 16.1675 24.6061 17.8102 24.6065 19.5119V21.8316C24.6065 22.012 24.5348 22.1851 24.4072 22.3127C24.2795 22.4404 24.1065 22.512 23.926 22.512Z" fill="#006400"/>
                                </g>
                            </svg>
                        </span>
                        <h1>En savoir plus</h1>

                    </div>

                    <motion.div className="sections">
                        <LayoutGroup id="sections">
                            <Section title="Objectif de Maapix" {...{open, toggleOpen}} name="aims">
                                <p>
                                    Maapix est un outil révolutionnaire basé sur l'intelligence artificielle (IA), conçu pour soutenir les entrepreneurs et les porteurs de projets dans leur quête de réussite. 
                                    Notre objectif est de fournir des analyses approfondies et personnalisées pour évaluer le potentiel des projets et les aider à prendre des décisions éclairées pour leur développement.
                                </p>
                            </Section>

                            <Section title="Comment ça fonctionne" {...{open, toggleOpen}} name="it_work">
                                <p>
                                    Maapix utilise une puissante IA, pour analyser en profondeur les projets soumis. Voici comment ça fonctionne en quelques étapes :
                                </p>
                                <div className="desc">
                                    <h4>1. Soumettez votre projet :</h4>
                                    Remplissez le formulaire détaillé pour présenter les informations clés de     votre projet à l'IA de Maapix.
                                </div>
                                <div className="desc">
                                    <h4>2. Analyse détaillée :</h4>
                                    Notre IA examine les données fournies, évalue le marché, les risques, la rentabilité, la viabilité opérationnelle et la durabilité de votre projet.
                                </div>
                                <div className="desc">
                                    <h4>3. Résultats personnalisés :</h4>
                                    Vous recevrez une speech de présentation percutante, un plan marketing sur mesure et des analyses approfondies pour vous aider à maximiser le potentiel de votre projet.
                                </div>
                                <div className="desc">
                                    <h4>4. Possibilité d'Investissement :</h4>
                                    Si vous le souhaitez, vous pouvez évaluer la possibilité d'investissement en soumettant votre projet via nos partenariats avec des structures de levée de fonds.
                                </div>
                            </Section>

                            <Section title="Politique de confidentialité" {...{open, toggleOpen}} name="politique">
                                <p>
                                    La présente Politique de Confidentialité décrit comment Maapix collecte, utilise, protège et divulgue les informations que vous nous fournissez 
                                    lorsque vous utilisez notre plateforme. Nous nous engageons à protéger votre vie privée et à assurer la sécurité de vos informations personnelles. 
                                    En utilisant Maapix, vous acceptez les pratiques décrites dans cette politique.
                                </p>

                                <h3>Collecte et Utilisation des Informations</h3>

                                <ol>
                                    <li>
                                        <h4>Informations Personnelles :</h4> 
                                        Lorsque vous utilisez notre plateforme, nous pouvons collecter des informations personnelles vous concernant, telles que votre nom, votre adresse e-mail et d'autres 
                                        données d'identification. Nous collectons ces informations lorsque vous nous les fournissez volontairement, par exemple lorsque vous remplissez un formulaire pour 
                                        soumettre votre projet ou pour vous inscrire à notre newsletter.

                                    </li>
                                    <li>
                                        <h4>Informations de Projet :</h4>
                                        Lorsque vous soumettez votre projet via notre formulaire, nous pouvons collecter des informations spécifiques concernant votre projet, telles que des détails sur l'entreprise, 
                                        le marché cible, le modèle commercial, etc. Ces informations sont utilisées pour fournir les analyses et les recommandations personnalisées de Maapix.
                                    </li>
                                    <li>
                                        <h4>Utilisation des Informations :</h4>
                                        Nous utilisons les informations collectées pour fournir nos services, notamment pour générer les analyses détaillées du potentiel de votre projet, pour améliorer notre plateforme 
                                        et pour communiquer avec vous concernant votre projet et nos services connexes.
                                    </li>
                                </ol>

                                <h3>Protection des Informations</h3>
                                <p>
                                    Nous prenons la sécurité de vos informations personnelles très au sérieux. Nous mettons en place des mesures de sécurité appropriées pour protéger vos données contre tout accès, utilisation, 
                                    divulgation ou modification non autorisée.
                                </p>

                                <h3>Partage des Informations</h3>
                                <p>
                                    Nous ne vendons, ne louons ni ne divulguons vos informations personnelles à des tiers, sauf dans les cas suivants :
                                </p>
                                <ul>
                                    <li>Lorsque vous nous donnez votre consentement explicite pour le partage de vos informations.</li>
                                    <li>Lorsque cela est nécessaire pour fournir les services que vous avez demandés, par exemple, pour soumettre votre projet à des structures de levée de fonds partenaires.</li>
                                    <li>Lorsque nous sommes tenus de le faire en vertu de la loi ou lorsque nous croyons de bonne foi que cela est nécessaire pour protéger nos droits, notre sécurité ou nos intérêts.</li>
                                </ul>

                                <h3>Cookies</h3>
                                <p>
                                    Maapix peut utiliser des cookies et des technologies similaires pour améliorer votre expérience sur notre plateforme. Les cookies sont de petits fichiers texte qui sont 
                                    stockés sur votre appareil lorsque vous visitez un site web. Ils nous permettent de suivre votre utilisation de la plateforme, de personnaliser votre expérience et d'analyser l'utilisation globale de Maapix.
                                </p>

                                <h3>Accès et Contrôle de Vos Informations</h3>
                                <p>
                                    Vous avez le droit d'accéder, de modifier ou de supprimer vos informations personnelles que nous détenons. Si vous souhaitez exercer ce droit ou si vous avez des questions concernant notre politique de confidentialité, veuillez nous contacter à l'adresse smartentreprisebenin@gmail.com.
                                </p>

                                <h3>Modification de la Politique de Confidentialité</h3>
                                <p>
                                    Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Toute modification sera publiée sur cette page et prendra effet immédiatement après sa publication.
                                    Dernière mise à jour : 1 Août 2023.
                                </p>
                                <p>
                                    En utilisant Maapix, vous acceptez les termes de cette Politique de Confidentialité.
                                    Nous vous remercions de faire confiance à Maapix pour l'analyse de votre projet. Si vous avez des questions ou des préoccupations concernant notre politique de confidentialité, veuillez nous contacter à l'adresse smartentreprisebenin@gmail.com.
                                </p>
                            </Section>
                        </LayoutGroup>

                    </motion.div>

                </div>
                <div className="bottom-logo">
                    <div className="logo"></div>
                </div>
            </Container>
        </div>
    )
}

const Section = ({title, children, open, toggleOpen, name})=>{

    return (
        <motion.div className="section" id={name}>
            <motion.div animate={{backgroundColor: open===name? "rgba(0,0,0,.1)": "rgba(0,0,0,0)"}} className="title" onClick={()=>toggleOpen(name)}>
                <h2>{title}</h2>

                <span style={{transform: "rotate(180deg)"}}>
                    <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-arrow-left-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                    </svg>
                </span>
            </motion.div>
            
            {/* <AnimatePresence>

                {open === name && ( */}
                    <motion.div className="children" 
                        initial="close"
                        animate={open===name?"open": "close"}

                        style={{overflow: "hidden"}}
                        
                        variants={{
                            open: {
                                opacity:1,
                                scale: 1,
                                height: null,
                            },
                            close:{
                                opacity: 0,
                                scale: .7, height: 0
                            }
                        }}
                    >
                        {children}
                    </motion.div>
                {/* )}
            </AnimatePresence> */}
        </motion.div>
    )
}


export default About;