

import { Link, useNavigate, useSearchParams } from "react-router-dom"
import Container from "../components/Container"
import { Button, Input, PhoneNumber } from "../components/tools/formTools"
import { motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import freader from "../components/tools/formReader";
import { useDispatch } from "react-redux";
import { patch, post } from "../store/action/request";
import { CHANGE_PASSWORD, LOGIN, LOGOUT, LOGOUT_ALL, RESET_PASSWORD, USER } from "../store/apiUrls";
import { setCredentials } from "../store/action/credential";
import { logout, setUser } from "../store/action/user";
import { addNotification } from "../store/action/notification";
import { clearErrors, setErrors } from "../store/action/alert";


const Auth = ({children, title}) =>{
    const [search] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return(
        <Container>
            <div style={{display: "flex", flexDirection: "column"}}>
                <div className="logo"></div>
                <motion.div className="card"
                    initial={{height: 0, width: 0}}
                    animate={{height: null, width: null}}
                    transition={{delay: .2, duration: .3, type: "spring", height: {delay: .4, duration: .4, type: "spring"}}}
                >
                    <div className="title">
                        <span
                            onClick={()=>{dispatch(clearErrors());navigate(search.get("back") || "/")}}
                        >
                            {BACK}
                        </span>
                        <h2>{title}</h2>
                    </div>

                    <div className="card-body">
                        {children}
                    </div>
                </motion.div>

            </div>
        </Container>
    )
}

export const Login = ()=>{

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [search] = useSearchParams();

    useEffect(()=>{
        dispatch(clearErrors());
    }, [dispatch])

    const submit = useCallback(e=>{
        e.preventDefault();
        const data = freader(e.target, ["email", "password"])


        dispatch(post({
            url: LOGIN,
            key: "login",
            data,
            success: data=>{
                dispatch(setCredentials({token: data.token, expiry: data.expiry}));
                dispatch(setUser(data.user));
                navigate(search.get("next") || "/dashboard");
            },

        }))
    }, [dispatch, navigate, search])

    return (
        <div className="auth login">
            <Auth title="Se connecter">
                <form onSubmit={submit}>
                    <div className="controls">
                        <Input label="E-mail" type="email" name="email" pk="email" required />
                        <Input label="Mot de passe" type="password" name="password" pk="password" required />

                        <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: 10, gap: "5px"}}>
                            <Button pk="login">Connexion</Button>

                            <Link to={`/auth/forgot-password?back=${window.location.pathname}`}>Mot de passe oublié?</Link>

                            <span>
                                Vous n'avez pas un compte? <span> </span>
                                <Link to={`/auth/register?back=${window.location.pathname}`}>S'enrégistrer</Link>
                            </span>
                        </div>
                    </div>

                </form>
            </Auth>
        </div>
    )
}

export const Logout = ()=>{
    const dispatch = useDispatch();
    const navigate = useNavigate();

   useEffect(()=>{
        dispatch(post({
            url: LOGOUT,
            auth: true,
            success: ()=>{dispatch(logout()); navigate("/")},
            // error: ()=>{dispatch(logout()); navigate("/")},
        }))
    }, [dispatch, navigate])

    return (
        <div className="auth logout">
            <div className="layer">
                <div className="loading big"></div>
            </div>
        </div>
    )
}

export const Register = ()=>{

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [number , setNumber] = useState("");

    useEffect(()=>{
        dispatch(clearErrors());
    }, [dispatch])

    const submit = useCallback(e=>{
        e.preventDefault();
        let data = freader(e.target, ["email", "fullName", "password"])

        data.phone_number = number;

        if(data.password !== e.target.confirm.value){
            dispatch(setErrors({confirm: ["Veuillez confirmer le mot de passe"]}))
            return;
        }

        dispatch(post({
            url: USER,
            key: "register",
            data,
            success: ()=>{
                dispatch(addNotification({
                    title: "Création de compte",
                    message:"Votre compte à été créer avec succès.",
                }));
                navigate("/auth/login");
            },
        }))
    }, [dispatch, navigate, number])

    return (
        <div className="auth register">
            <Auth title="S'enrégistrer">
                <form onSubmit={submit}>
                    <div className="controls">
                        <Input label="Nom et prénom" name="fullName" required pk='full_name'/>
                        <PhoneNumber label="Votre Numéro" name="phoneNumber" pk="phone_number" value={number} setValue={setNumber} />
                        <Input label="E-mail" type="email" name="email" required pk="email" />
                        <Input label="Mot de passe" type="password" name="password" required pk="password"/>
                        <Input label="Confirmer mot de passe" type="password" name="confirm" required pk="confirm"/>

                        <div style={{display: "flex", justifyContent: "flex-end", fontSize: ".7em"}}>
                            <Link to="/about/?of=politique&back=/auth/register">Politique de confidentialité</Link>
                        </div>

                        <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: 10, gap: "5px"}}>
                            <Button pk="register">Enrégistrer</Button>

                            <span>
                                Vous avez un compte? <span> </span>
                                <Link to={`/auth/login?back=${window.location.pathname}`}>Se connecter</Link>
                            </span>
                        </div>
                    </div>

                </form>
            </Auth>
        </div>
    )
}

export const ForgotPass = ()=>{
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(()=>{
        dispatch(clearErrors());
    }, [dispatch])

    const submit = useCallback(e=>{
        e.preventDefault();
        const data = {email: e.target.email.value};

        dispatch(post({
            url: RESET_PASSWORD,
            data,
            key: "reset-password",
            success: ()=>{
                dispatch(addNotification({
                    message: "Un code de récupération vous est envoyé sur " + data.email
                }, 5000));

                navigate("/auth/reset-password?email="+data.email);
            },
        }))

    }, [navigate, dispatch])

    return (
        <div className="auth login">
            <Auth title="Mot de passe oublié">
                <form onSubmit={submit}>
                    <div className="controls">
                        <Input label="E-mail" type="email" name="email" required/>

                        <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: 10, gap: "5px"}}>
                            <Button key="reset-password">Envoyer</Button>
                        </div>
                    </div>

                </form>
            </Auth>
        </div>
    )
}

export const ResetPass = ()=>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [search] = useSearchParams();

    useEffect(()=>{
        dispatch(clearErrors());
    }, [dispatch])

    useEffect(()=>{
        const code = search.get("code");
        
        if(code){
            let c = document.querySelector("#code");
            c.value = code
        }
    }, [search])

    const submit = useCallback(e=>{
        e.preventDefault();
        let data = freader(e.target, ["code", "password"]);

        data["email"] = search.get("email");

        if(!data.email){
            navigate("/auth/forgot-password");
            return;
        }
        
        if(data.password !== e.target.confirm.value){
            dispatch(setErrors({confirm: ["Veuillez confirmer le mot de passe"]}))
            return;
        }
        dispatch(post({
            url: RESET_PASSWORD,
            data,
            key: "reset-ipassword",
            success: ()=>{
                dispatch(addNotification({
                    title: "Nouveau mot de passe",
                    message: "Mot de passe changé avec succès"
                }, 5000));

                navigate("/auth/login");
            },
        }))
    }, [dispatch, navigate, search])

    return (
        <div className="auth login">
            <Auth title="Définir mot de passe">

                <form onSubmit={submit}> 

                    <div className="controls">
                        <Input label="Code" type="number" name="code" required pk="code" id="code"/>
                        <Input label="Nouveau mot de passe" type="password" name="password" required pk="password"/>
                        <Input label="Confirmé" type="password" name="confirm" required pk="confirm" />

                        <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: 10, gap: "5px"}}>
                            <Button pk="reset-ipassword">Enrégistrer</Button>
                        </div>
                    </div>

                </form>
            </Auth>
        </div>
    )
}

export const ChangePass = ()=>{
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(()=>{
        dispatch(clearErrors());
    }, [dispatch])
    
    const submit = useCallback(e=>{
        e.preventDefault();
        let data = freader(e.target, ["oldPassword", "newPassword"]);
        if(data.new_password !== e.target.confirm.value){
            dispatch(setErrors({confirm: ["Veuillez confirmer le mot de passe"]}))
            return;
        }
        dispatch(patch({
            url: CHANGE_PASSWORD,
            data,
            auth: true,
            key: "change-password",
            success: ()=>{
                dispatch(addNotification({
                    title: "Nouveau mot de passe",
                    message: "Mot de passe changé avec succès"
                }, 5000));

                dispatch(post({
                    url: LOGOUT_ALL,
                    auth: true,
                    success: ()=>{
                        dispatch(logout());
                        navigate("/auth/login");
                    }
                }))
            },
            logout401: true
        }))
    }, [dispatch, navigate])

    return (
        <div className="auth login">
            <Auth title="Changer mot de passe">
                <form onSubmit={submit}>
                    <div className="controls">
                        <Input label="Ancien mot de passe" type="password" name="oldPassword" pk="old_password" required/>
                        <Input label="Nouveau mot de passe" type="password" name="newPassword" pk="new_password" required/>
                        <Input label="Confirmer" type="password" name="confirm" pk="confirm"/>

                        <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: 10, gap: "5px"}}>
                            <Button pk="change-password">Changer</Button>

                            <Link to={`/auth/forgot-password?back=${window.location.pathname}`}>Mot de passe oublié?</Link>

                        </div>
                    </div>

                </form>
            </Auth>
        </div>
    )
}


const BACK = (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="back-interface-ui-ux_svgrepo.com">
            <path id="Vector" d="M23.926 22.512C23.8186 22.5122 23.7128 22.487 23.6171 22.4384C23.5214 22.3897 23.4386 22.3191 23.3755 22.2323L22.2308 20.6611C21.625 19.8306 20.8317 19.1549 19.9154 18.689C18.9991 18.2231 17.9857 17.9802 16.9578 17.98H16.049V18.9478C16.049 19.8521 15.5424 20.6551 14.7258 21.0437C14.3305 21.2347 13.8887 21.3086 13.4527 21.2566C13.0168 21.2047 12.6048 21.029 12.2654 20.7503L7.25251 16.6825C6.98428 16.4649 6.768 16.1902 6.61944 15.8783C6.47089 15.5665 6.3938 15.2254 6.3938 14.88C6.3938 14.5346 6.47089 14.1935 6.61944 13.8817C6.768 13.5698 6.98428 13.2951 7.25251 13.0775L12.2654 9.00966C12.6048 8.73103 13.0168 8.55534 13.4527 8.50336C13.8887 8.45137 14.3305 8.52526 14.7258 8.71629C15.5424 9.10493 16.049 9.9079 16.049 10.8122V12.2987L18.4836 12.5951C20.1726 12.8018 21.7276 13.6193 22.8555 14.8934C23.9833 16.1675 24.6061 17.8102 24.6065 19.5119V21.8316C24.6065 22.012 24.5348 22.1851 24.4072 22.3127C24.2795 22.4404 24.1065 22.512 23.926 22.512Z" fill="#006400"/>
        </g>
    </svg>
)
