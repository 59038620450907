import { AnimatePresence, Reorder, motion } from "framer-motion";
import { rgbaDefault } from "../../styles/style";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors } from "../../store/action/alert";
import TextTruncate from "react-text-truncate";
// import _ from "lodash";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'


export const Button = ({onClick, children, className, animate, pk, ...props})=>{
    const dispatch = useDispatch();
    const {isLoading} = useSelector(state=>state.request);

    const clicked = useCallback(()=>{
        dispatch(clearErrors());
        onClick?.();

    }, [onClick, dispatch])

    

    return (
        <div className="btn-wrapper" 
            style={{
                overflow: "hidden", display: "flex", justifyContent: "center", 
                padding: 3, alignItems: "center", 
                // minWidth: btn.current?.getClientRef().width
            }}
        >
            <AnimatePresence>
                {pk && isLoading[pk] && (
                    <motion.div exit={{scale: 0}} className="loading" layoutId={pk} ></motion.div>
                )}
            </AnimatePresence>

            <motion.button
                {...props}
                onClick={clicked}
                whileTap={{scale: .97}}
                whileHover={{
                    scale: 1.01, 
                    borderColor: rgbaDefault(1), 
                    boxShadow: `0px 0px 2px 1px ${rgbaDefault(.5)}`
                }}
                animate={animate}
                className={`btn ${className}`}
                layoutId={pk}

            >
                {children}               
            </motion.button>
        </div>
    )
}


export const CheckBtn = ({children, className, checked, toggle, ...props})=>{

    return (
        <Button onClick={toggle} className={`checkable-btn ${className}`} {...props} type="button" >
            <div style={{display: "flex", alignItems: "center"}}>
                <motion.svg width="1.2em" height="1.2em" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Frame 42">
                    <g id="Group 1">
                        
                    <motion.circle id="Ellipse 1" cx="10" cy="12" fill="#D9D9D9" stroke="#000066"
                        initial={{r: 5.5}}
                        animate={{r: checked? 9.5: 5.5}}
                    />

                    <motion.circle 
                        initial={{r: 0}} animate={{r: checked?5.5: 0}} 
                        transition={{delay: .2}}
                        id="Ellipse 2" cx="10" cy="12" fill="#000066" stroke="#000066"
                    />
                
                    </g>
                    </g>
                </motion.svg>
                <div style={{marginLeft: 5}}>{children}</div>
                
            </div>
        </Button>
    )
}

const InputWrapper = ({label, pk, children, open, setOpen}) =>{
    const errors = useSelector(state=>state.alert.errors);

    return (
        <motion.div className="input" layout
        
        >
            <AnimatePresence>
                {open &&(
                    <motion.div 
                        className="legend" 
                        initial={{opacity: 0, y: 10}}
                        animate={{opacity: 1, y: 0}}
                        exit={{opacity: 0, y: 10}}
                    >
                        {label}
                    </motion.div>
                )}
            </AnimatePresence>

            <motion.div 
                className="input-container" onClick={()=>!open&&setOpen(true)}
                initial="free"
                animate={open? "focus": "free"}
                whileHover="hover"
                variants={{
                    free: {border: `2px solid ${rgbaDefault(.3)}`},
                    focus: {boxShadow: `0 0 3px 1px ${rgbaDefault(.5)}`, border: `2px solid ${rgbaDefault(.7)}`},
                    hover: {border: `2px solid ${rgbaDefault(.7)}`}
                }}
                // transition={{border:{delay: 1}, whileHover:{duration: 0}}}
            >                   
                {children}

            </motion.div>

            {errors[pk] && (
                <ul>
                    {errors[pk].map((txt, i)=>(
                        <motion.li key={i}>
                            {txt}
                        </motion.li>
                    ))}
                </ul>
            )}
        </motion.div>
    )
}

export const Input = ({label, type, pk, placeholder, ...props}) =>{
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState("");

    return (
        <InputWrapper {...{label, pk, open, setOpen}}>
            {type !== "textarea"? (
                <input 
                    placeholder={open?placeholder:label} 
                    type={type} autoFocus={open} 
                    // animate={{opacity: 1, transition:{duration: .3}}}  
                    value={value} onChange={e=>setValue(e.target.value)} 
                    onBlur={()=>setOpen(false)}
                    {...props}
                
                />
            ): (
                <textarea 
                    placeholder={open?placeholder:label} 
                    autoFocus={open} 
                    // animate={{opacity: 1, transition:{duration: .3}}}  
                    value={value} onChange={e=>setValue(e.target.value)} 
                    onBlur={()=>setOpen(false)}
                    {...props}
                />
            )}
        </InputWrapper>
    )
}

export const PhoneNumber = ({label, pk, name, value, setValue})=>{
    const [val, setVal] = useState("");
    const [open, setOpen] = useState(false);
    
    return (
        <InputWrapper {...{label, pk, open, setOpen}}>
            <PhoneInput
                value={value || val}
                onChange={setValue || setVal}
                placeholder="Numéro de téléphone"
                onBlur={()=>setOpen(false)}
                name={name}
            />
        </InputWrapper>
    )
}


export const MultipleInput = ({label, placeholder, onChange, initial=[], newAdd=()=>{}, type="text", remove=()=>{}})=>{
    const [values, setValues] = useState(initial || []);
    const [value, setValue] = useState("");
    const onchange = useRef({i: onChange}).current.i
    const [display, setDisplay] = useState(false);
    const ref = useRef();

    useLayoutEffect(()=>{
        setTimeout(()=>setDisplay(true), 200)
    }, [])

    const add = useCallback(()=>{
        const valid = ref.current?.checkValidity();
        if(!valid || !value) {
            ref.current?.reportValidity();
            return
        };

        setValues(v=>{
            const exist = v.includes(value)
            
            if(!exist){
                newAdd?.(value)
            }
            return exist? v: [...v, value]
        });
        setValue("");
    }, [value, newAdd, ref])

    useEffect(()=>{
        // console.log(onchange)
        // onchange(["55555dksksk"])
        onchange?.(values)
    }, [values, onchange])

    const rm = useCallback(i=>{
        setValues(v=>{
            remove?.(v[i]);
            return [...v.slice(0, i), ...v.slice(i+1, v.length)];
        })
    }, [remove])

    return (
        <div className="multiple-input">
            <fieldset>
                <legend>{label}</legend>
                <div className="values">
                    {values.length >0? (
                        <Reorder.Group axis="y" values={values} onReorder={setValues}>
                            {display && values.map((v, i)=>(
                                
                                <Reorder.Item key={i} value={v} className="item">
                                    <span style={{transform: "rotate(90deg)", opacity: .4}}>
                                        <svg width="15" height="100%" viewBox="0 0 16 16" className="bi bi-grid-3x2-gap-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V4zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V4zM1 9a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V9zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V9zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V9z"/>
                                        </svg>
                                    </span>
    
                                    <div style={{flex: 1}} title={v}>
                                        <TextTruncate 
                                            line={1}
                                            text={v}
                                        />
                                    </div>
                                
                                    <span style={{cursor: "pointer"}} onClick={()=>rm(i)}>
                                        <svg width="20" height="1em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                            <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                        </svg>    
                                    </span>    
                                </Reorder.Item>
                            ))}

                        </Reorder.Group>
                    ):(
                        <div className="placeholder">
                            {placeholder}
                        </div>
                    )}
                </div>
                <div >
                    <div onSubmit={add} className="enter">
                        <input ref={ref} name="value" value={value} onChange={e=> setValue(e.target.value)} type={type} />
                        <button type="button" onClick={add}>Ajouter</button>
                    </div>
                </div>
            </fieldset>
        </div>
    )
}

