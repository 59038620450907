export const HOST = 
// "http://127.0.0.1:8000/";
// "http://192.168.43.185:8000/";
// "http://192.168.1.115:8000/";
// "http://172.20.10.13:8000/";
// "http://192.168.1.103:8000/";
"https://api.maapix.projetsmart.com/";


export const APP_HOST = 
// "http://127.0.0.1:3000/";
// "http://192.168.43.185:3000/";
// "http://192.168.1.115:3000/";
// "http://172.20.10.13:3000/";
// "http://192.168.1.103:3000/";
"https://maapix.projetsmart.com/";


// export const WS_HOST = 
// "ws://127.0.0.1:8000/";
// // "ws://192.168.43.185:8000/";
// // "ws://192.168.1.115:8000/";
// // "ws://172.20.10.13:8000/";
// // "ws://192.168.1.103:8000/";

export const PROJECTS = "projects/";
export const PROJECTS_SUBMITED = "projects/?other=True";
export const PROJECT = pk => "projects/"+pk+'/';
export const SUBMIT = "projects/submit/";
export const download = (pk, name)=> HOST + "projects/" + pk + "/export/"+name + "/";
export const INTERESTED = pk => "projects/" + pk + "/interested/";
export const SHARE = (pk, rm=false) => `projects/${pk}/share/${rm?"?remove=True": ""}`;

export const GET_ANALYSE = (pk, of)=> `projects/${pk}/get-analyse/${of}/`;

export const RESET_PASSWORD = "users/reset-password/";
export const CHANGE_PASSWORD = "users/change-password/";
export const LOGIN = "users/knox-login/";
export const LOGOUT = "users/knox-logout/";
export const LOGOUT_ALL = "users/knox-logout-all/";
export const PROFILE = "users/me/";
export const USER = "users/";
