import { useEffect, useState } from "react";
import Container from "../components/Container"
import Menu from "../components/Menu";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get } from "../store/action/request";
import { PROJECTS, PROJECTS_SUBMITED } from "../store/apiUrls";
import _ from "lodash";


const Dashboard = ({submitted})=>{
    const [search, setSearch] = useState();

    const [projects, setProjects] = useState([]);
    const dispatch = useDispatch();
    const [filtered, setFiltered] = useState([]);
    const {isLoading} = useSelector(state=>state.request);


    useEffect(()=>{
        if(search){
            setFiltered(_.filter(projects, i=>i.detail.name.toLowerCase().includes(search.toLowerCase())));
        }else{
            setFiltered(projects)
        }
    }, [projects, search])

    useEffect(()=>{
        dispatch(get({
            url: submitted? PROJECTS_SUBMITED:PROJECTS ,
            auth: true,
            success: setProjects,
            key: "projects",

        }))
    }, [dispatch, submitted])

    return (
        <div className="dashboard viewport-wrapper">
            <Container requiredAuth={true}>
                <Menu/>
                <div className="viewport">
                    
                    <div className="header">
                        <div className="title">
                            <div style={{width: 40}}>
                                {search === undefined && (
                                    <motion.span onClick={()=>setSearch("")} layoutId="search">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="39" height="42" viewBox="0 0 39 42" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M1.625 8.75C1.625 5.85051 3.80762 3.5 6.5 3.5H13.7125C15.9495 3.5 17.8994 5.13956 18.442 7.47668L19.1438 10.5H21.9375H32.5C35.1925 10.5 37.375 12.8505 37.375 15.75V19.25C37.375 20.2165 36.6475 21 35.75 21C34.8525 21 34.125 20.2165 34.125 19.25V15.75C34.125 14.7835 33.3975 14 32.5 14H21.9375H19.1438H6.5C5.60255 14 4.875 14.7835 4.875 15.75V17.5V33.25C4.875 34.2165 5.60255 35 6.5 35H14.625C15.5225 35 16.25 35.7835 16.25 36.75C16.25 37.7165 15.5225 38.5 14.625 38.5H6.5C3.80762 38.5 1.625 36.1496 1.625 33.25V17.5V15.75V8.75ZM4.875 10.7987C5.38327 10.6053 5.93021 10.5 6.5 10.5H15.7937L15.289 8.32556C15.1081 7.54653 14.4581 7 13.7125 7H6.5C5.60255 7 4.875 7.78351 4.875 8.75V10.7987ZM32.7789 32.8255C33.6295 31.4423 34.125 29.7834 34.125 28C34.125 23.1676 30.4873 19.25 26 19.25C21.5127 19.25 17.875 23.1676 17.875 28C17.875 32.8325 21.5127 36.75 26 36.75C27.656 36.75 29.1964 36.2164 30.4808 35.3003L34.601 39.7374C35.2355 40.4208 36.2645 40.4208 36.899 39.7374C37.5336 39.0541 37.5336 37.946 36.899 37.2626L32.7789 32.8255ZM21.125 28C21.125 25.1004 23.3075 22.75 26 22.75C28.6925 22.75 30.875 25.1004 30.875 28C30.875 30.8996 28.6925 33.25 26 33.25C23.3075 33.25 21.125 30.8996 21.125 28Z" fill="#D3D3D3"/>
                                        </svg>
                                    </motion.span>
                                )}
                            </div>
                            <h1>Historique des projets</h1>

                        </div>
                        
                        <AnimatePresence >
                            {search !== undefined && (
                                <motion.div layoutId="search" className="search">
                                    <motion.input autoFocus placeholder="Rechercher" type="search" value={search} onChange={e=>setSearch(e.target.value)} />
                                    
                                    <span onClick={()=>setSearch(undefined)} style={{fontSize: "1.5em"}}>
                                        &times; 
                                    </span>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>

                    <motion.div className="projects">
                        <AnimatePresence mode="popLyout">
                            {filtered.map(p=>(
                                <motion.div 
                                    key={p.pk}
                                    exit={{scale: 0, opacity: 0}}
                                    initial={{scale: 0, opacity:0}}
                                    animate={{scale: 1, opacity:1}}
                                    transition={{type: "spring"}}
                                    layout
                                >
                                    <Project project={p} submitted={submitted} />
                                </motion.div>
                            ))}
                        </AnimatePresence>

                        {filtered.length === 0 && (
                            <div className="empty">
                                Aucun projet retrouvé
                            </div>
                        )}
                    </motion.div>
                </div>
                
                {isLoading["projects"] && (
                    <div className="layer">
                        <div className="loading big"></div>
                    </div>

                )}
            </Container>
        </div>
    )
}

const Project = ({project, submitted})=>{
    const [hover, setHover] = useState(false);
    return (
        <Link to={`/dashboard/project/${project.pk}`}>
            <motion.div className="project" title={project.detail.name} onHoverEnd={()=>setHover(false)} onHoverStart={()=>setHover(true)}>
                <span className="badge">
                    {submitted? project.is_interested &&(
                        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="green" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.523-3.356c.329-.314.158-.888-.283-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767l-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288l1.847-3.658 1.846 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.564.564 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
                        </svg>
                    ): project.submit &&(
                        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="green" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
                            <path fillRule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                        </svg>
                    )}
                </span>
                {!hover?(
                    <motion.span >
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <path d="M30.0001 16.6667H21.6667" stroke="#006400" strokeOpacity="0.5" strokeWidth="3.75" strokeLinecap="round"/>
                            <path d="M16.6667 5H27.5001C28.2741 5 28.6611 5 28.9861 5.04278C31.2298 5.33817 32.9953 7.1037 33.2906 9.34736C33.3334 9.67228 33.3334 10.0593 33.3334 10.8333" stroke="#006400" strokeOpacity="0.5" strokeWidth="3.75"/>
                            <path d="M36.6666 19.6632C36.6666 15.2759 36.6666 13.0823 35.3841 11.6564C35.2663 11.5252 35.1414 11.4004 35.0103 11.2824C33.5843 10 31.3908 10 27.0034 10H26.3806C24.4578 10 23.4964 10 22.6006 9.74464C22.1084 9.60434 21.6339 9.40786 21.1868 9.15906C20.3728 8.70613 19.6929 8.02631 18.3333 6.66668L17.4161 5.74959C16.9604 5.29389 16.7326 5.06603 16.4932 4.86753C15.4608 4.01174 14.1943 3.48716 12.8592 3.36231C12.5495 3.33334 12.2273 3.33334 11.5828 3.33334C10.112 3.33334 9.37651 3.33334 8.7639 3.44893C6.0671 3.95774 3.95765 6.06719 3.44884 8.76399C3.33325 9.37659 3.33325 10.112 3.33325 11.5829M36.6521 26.6667C36.5923 30.7993 36.2858 33.1422 34.7139 34.714C32.7614 36.6667 29.6186 36.6667 23.3333 36.6667H16.6666C10.3812 36.6667 7.2385 36.6667 5.28587 34.714C3.33325 32.7615 3.33325 29.6187 3.33325 23.3333V18.3333" stroke="#006400" strokeOpacity="0.5" strokeWidth="3.75" strokeLinecap="round"/>
                        </svg>
                    </motion.span>
                ):(
                    <motion.span >
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <g clipPath="url(#clip0_50_47)">
                                <path d="M40 0H0V40H40V0Z" fill="white"/>
                                <path d="M6.66675 15V10.7869C6.66675 10.2694 6.78723 9.75902 7.01866 9.29617L8.33341 6.66666H16.6667L18.3334 9.99999H35.0001C35.9206 9.99999 36.6667 10.7462 36.6667 11.6667V15V30C36.6667 31.841 35.1744 33.3333 33.3334 33.3333H30.0001" stroke="#006400" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M28.727 15H3.84876C2.74981 15 1.95166 16.045 2.24083 17.1052L5.99678 30.877C6.39229 32.3273 7.70949 33.3333 9.21266 33.3333H32.818C33.9168 33.3333 34.715 32.2883 34.4258 31.2282L30.335 16.2281C30.1372 15.503 29.4787 15 28.727 15Z" stroke="#006400" strokeWidth="2.5"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_50_47">
                                <rect width="40" height="40" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </motion.span>
                )}
                <span>{project.detail.name}</span>
            </motion.div>
        </Link>
    )
}



export default Dashboard;
