import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Container from "../components/Container";
import Menu from "../components/Menu";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { get, post } from "../store/action/request";
import { GET_ANALYSE, INTERESTED, PROJECT, SHARE, SUBMIT, download } from "../store/apiUrls";
import { Button, MultipleInput } from "../components/tools/formTools";

// const project = {name: "Projet de conciergerie", pk: "djdksld"}

const Project = ()=>{
    const navigate = useNavigate();
    const [search] = useSearchParams();
    const dispatch = useDispatch();
    const {pk} = useParams();
    const [project, setProject] = useState({});
    const [display, setDisplay] = useState();
    // const {isLoading} = useSelector(state=>state.request);
    const user = useSelector(state=>state.user);

    const getAnalyse = useCallback((of)=>{

        if(of === "detail") return;
        else if( of && project.analysis?.[of] === null){
        // else if( of ){
            dispatch(get({
                url: GET_ANALYSE(project.pk, of),
                success: setProject,
                key: of,
            }))
        }
    }, [project, dispatch])

    // const i = useCallback(()=>{
    //     dispatch(get({
    //         url: GET_ANALYSE(project.pk, display),
    //         success: setProject,
    //         key: display,
    //     }))
    // }, [dispatch, project, display])

    useEffect(()=>{
        if(display && (display === "detail" || project.analysis?.[display])){
            let container = document.querySelector(".viewport")
           
            container.scrollTo({top: 0, behavior: 'smooth',})
        
        }
    }, [project, dispatch, display])

    useEffect(()=>{
        getAnalyse(search.get("display"))
        setDisplay(search.get("display"));

    }, [search, getAnalyse])
    
    useEffect(()=>{
        if(!pk) navigate("/dashboard");

        dispatch(get({
            url: PROJECT(pk),
            auth: true,
            success: setProject,
            error: ()=>navigate("/dashboard")
        }))
    }, [dispatch, pk, navigate])

    const submit = useCallback(()=>{
        dispatch(post({
            url: SUBMIT,
            auth: true,
            data: {
                pk: project.pk,
            },
            success: setProject,
            key: "submit"
        }))
    }, [dispatch, project])

    const interested = useCallback(()=>{
        dispatch(post({
            url: INTERESTED(project.pk),
            success: setProject(p=>({...p, is_interested: true})),
            key: "interested",
            auth: true
        }))
    }, [project, dispatch])

    return (
        <div className="dashboard-project viewport-wrapper">
            <Container requiredAuth={true}>
                <Menu/>
                {/* <button onClick={i}>HIII</button> */}
                <div className="viewport">

                    <div className="title">
                        <span onClick={()=>display? navigate("/dashboard/project/"+pk) :navigate(search.get("back")||"/dashboard")}>
                            <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="back-interface-ui-ux_svgrepo.com">
                                    <path id="Vector" d="M23.926 22.512C23.8186 22.5122 23.7128 22.487 23.6171 22.4384C23.5214 22.3897 23.4386 22.3191 23.3755 22.2323L22.2308 20.6611C21.625 19.8306 20.8317 19.1549 19.9154 18.689C18.9991 18.2231 17.9857 17.9802 16.9578 17.98H16.049V18.9478C16.049 19.8521 15.5424 20.6551 14.7258 21.0437C14.3305 21.2347 13.8887 21.3086 13.4527 21.2566C13.0168 21.2047 12.6048 21.029 12.2654 20.7503L7.25251 16.6825C6.98428 16.4649 6.768 16.1902 6.61944 15.8783C6.47089 15.5665 6.3938 15.2254 6.3938 14.88C6.3938 14.5346 6.47089 14.1935 6.61944 13.8817C6.768 13.5698 6.98428 13.2951 7.25251 13.0775L12.2654 9.00966C12.6048 8.73103 13.0168 8.55534 13.4527 8.50336C13.8887 8.45137 14.3305 8.52526 14.7258 8.71629C15.5424 9.10493 16.049 9.9079 16.049 10.8122V12.2987L18.4836 12.5951C20.1726 12.8018 21.7276 13.6193 22.8555 14.8934C23.9833 16.1675 24.6061 17.8102 24.6065 19.5119V21.8316C24.6065 22.012 24.5348 22.1851 24.4072 22.3127C24.2795 22.4404 24.1065 22.512 23.926 22.512Z" fill="#006400"/>
                                </g>
                            </svg>
                        </span>
                        <h1>
                            {project.detail?.name} 
                            {display && (
                                <span> ( {TITLE[display]} )</span>
                            )}
                        </h1>
                        {project.owner === user.pk && (
                            <Share project={project} setProject={setProject}/>
                        )}
                    </div>

                    <div className="display">
                        <AnimatePresence>
                            {display === "detail"?(
                                <Detail project={project} />
                            ): display && project.analysis?.[display] && (
                                <Format txt={project.analysis[display]}/>
                            )}
                        </AnimatePresence>
                    </div>

                    <motion.div 
                        className="table"
                        initial={{y: -100, opacity: 0}}
                        animate={{y: 0, opacity: 1}}
                        transition={{delay: .1}}
                    >
                        <h3>À propos</h3>
                        <div className="table-content">
                            {["detail", "speech", "marketing"].map((name, i)=>(
                                <File name={name} done={name==="detail" || project.analysis?.[name]} key={i} />
                            ))}
                        </div>
                    </motion.div>

                    <motion.div 
                        className="table"
                        initial={{y: -100, opacity: 0}}
                        animate={{y: 0, opacity: 1}}
                        transition={{delay: .5}}
                    >
                        <h3>Analyses de potentiel</h3>
                        <div className="table-content">
                            {[
                                "market", "rentable", "risk", "tech",
                                "viability", "durability", "conclusion",
                            ].map((name, i)=>(
                                <File name={name} done={project.analysis?.[name]} key={i} />
                            ))}
                        </div>
                    </motion.div>
                    
                    {(project.pk && !project.submit) ? (
                        <div className="submit">
                            <p>
                                Prêt à passer à l'étape suivante ? Explorez les opportunités d'investissement dès maintenant en soumettant votre projet !
                            </p>
                            <Button onClick={submit} key="submit">Soumettre</Button>
                        </div>
                    ): project.pk && !project.is_interested && user?.permissions?.partner && (
                        <div className="submit">
                            <Button onClick={interested} key="interested">Je suis interresé(e)</Button>
                        </div>
                    )}
                    
                </div>
                {display && (display === "detail" || project.analysis?.[display]) &&(
                    <motion.a 
                        className="download" 
                        // onClick={()=>window.location.href = } 
                        href={download(project.pk, display)}
                        whileHover={{scale: 1.2}}
                        transition={{type: "spring", stiffness: 400}}
                        // title="Télécharger"
                        target="_blank"
                    >

                    </motion.a>
                )}
            </Container>
        </div>
    )
}

const File = ({name, done})=>{
    const {pk} = useParams();
    const {isLoading} = useSelector(state=>state.request);
    const [search] = useSearchParams();

    return (
        <motion.div layoutId={name}
            initial={{scale: 1}}
            animate={{
                scale: done && search.get("display") === name? .8: 1
            }}
            whileTap={{scale: .9}}
            transition={{type: "spring"}}
        >

            <Link to={`/dashboard/project/${pk}/?display=${name}`}>

                <motion.div className="file"
                    variants={{
                        free: {boxShadow:"0 0 1px 2px rgba(0,0,0, .3)", scale: 1},
                        hover: {boxShadow:"0 0 2px 2px rgba(0,0,0, .5)", scale: 1.01},
                    }}
                    initial="free"
                    whileHover="hover"
                >   
                    {isLoading[name]?(
                        <div style={{height: 40, width: 40, position: "relative"}}>
                            <div className="loading" style={{position: "absolute"}}></div>
                        </div>
                    ):done?(
                        
                        <svg width="40" height="40" viewBox="0 0 16 16" className="bi bi-file-earmark-post" fill="green" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/>
                            <path fill="rgba(0, 100, 0, .4)" d="M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3zM4 6.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-7z"/>
                            <path fill="rgba(0, 100, 0, .4)" fillRule="evenodd" d="M4 3.5a.5.5 0 0 1 .5-.5H7a.5.5 0 0 1 0 1H4.5a.5.5 0 0 1-.5-.5z"/>
                        </svg>
                    ): (
                        <svg width="40" height="40" viewBox="0 0 16 16" className="bi bi-file-earmark-check" fill="green" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/>
                            <path fill="rgba(0, 100, 0, .4)" fillRule="evenodd" d="M4 3.5a.5.5 0 0 1 .5-.5H7a.5.5 0 0 1 0 1H4.5a.5.5 0 0 1-.5-.5z"/>
                            <path fill="rgba(0, 100, 0, .4)" d="M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z"/>
                        </svg>
                    )}
                    <span>{TITLE[name]}</span>
                </motion.div>
            </Link>
        </motion.div>
    )
}

const Format = ({txt=""})=>{
    if(!txt) return;

    const lines  = txt.split("\n")

    return (
        <motion.div>
            {lines.map((line, index) => (
                <p key={index}>{line}</p>
            ))}
        </motion.div>
    )
}

export const Detail = ({project})=>{
    if(!project?.detail) return
    return (
        <div className="project-detail">
            <div className="row">
                <h2>Général</h2>
                

                {["desc", "sector"].map((k)=>(
                    <div className="desc" key={k}>
                        <h3>{LABEL.detail[k]}</h3>
                        <p>{project.detail[k]}</p>
                    </div>
                ))}
                <div className="desc">
                        <h3>{LABEL.detail["aims"]}</h3>
                        <ul>
                            {project.detail.aims.map((v, k)=>(
                                <li key={k}>{v}</li>
                            ))}
                        </ul>
                </div>
                
            </div>

            <div className="row">
                <h2>Marché cible</h2>

                {["desc", "size"].map((k)=>(
                    <div className="desc" key={k}>
                        <h3>{LABEL.market[k]}</h3>
                        <p>{project.market[k]}</p>
                    </div>
                ))}
                <div className="desc">
                    <h3>{LABEL.market["proprieties"]}</h3>
                    <ul>
                        {project.market.proprieties?.map((v, k)=>(
                            <li key={k}>{v}</li>
                        ))}
                    </ul>
                </div>
                
            </div>

            <div className="row">
                <h2>Modèle économique</h2>

                {["desc", "source", "outlay"].map((k)=>(
                    <div className="desc" key={k}>
                        <h3>{LABEL.economy[k]}</h3>
                        <p>{project.economy[k]}</p>
                    </div>
                ))}
                
            </div>
            <div className="row">
                <h2>Avantages concurrentiels</h2>

                <div className="desc">
                    <h3>{LABEL.advantage["advantage"]}</h3>
                    <ul>
                        {project.advantage.advantage?.map((v, k)=>(
                            <li key={k}>{v}</li>
                        ))}
                    </ul>
                </div>
            </div>
            
            <div className="row">
                <h2>État d'avancement du projet</h2>

                <div className="desc">
                    <h3>{LABEL.state["current"]}</h3>
                    <p>{project.state["current"]}</p>
                </div>

                <div className="desc">
                    <h3>{LABEL.state["realizations"]}</h3>
                    <ul>
                        {project.state.realizations?.map((v, k)=>(
                            <li key={k}>{v}</li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="row">
                <h2>Autres informations</h2>

                <div className="desc">
                    <h3>{LABEL.other["important"]}</h3>
                     <ul>
                        {project.other.important?.map((v, k)=>(
                            <li key={k}>{v}</li>
                        ))}
                    </ul>
                </div>
            </div>
            
        </div>
    )
}

const Share = ({project, setProject})=>{
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState(project.shares);
    const dispatch = useDispatch();

    useEffect(()=>{
        values && setProject?.(p=>({...p, shares: values}))
    }, [values, setProject])

    const add = useCallback((v)=>{
        dispatch(post({
            url: SHARE(project.pk),
            key: 'share',
            auth: true,
            data:{
                email: v
            }
        }))
    }, [project, dispatch])

    const rm = useCallback((v)=>{
        dispatch(post({
            url: SHARE(project.pk, true),
            key: 'share',
            auth: true,
            data: {
                email: v
            }
        }))
    }, [project, dispatch])

    return (
        <motion.div className="share">
            <Button pk="share" onClick={()=>setOpen(i=>!i)}>{open?"Fermer": "Inviter"}</Button>
            <AnimatePresence>
                {open&&(
                    <motion.div className="share-body"
                        initial={{scale: 0, opacity: 0}}
                        exit={{scale: 0, opacity: 0}}
                        animate={{scale: 1, opacity: 1}}
                    >
                        <MultipleInput
                            label="Liste de partage"
                            placeholder="Inviter une personne en ajoutant son address email"
                            type="email"
                            initial={project.shares || []}
                            newAdd={add}
                            onChange={setValues}
                            remove={rm}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    )
}


const TITLE = {
    "detail": "Détails",
    "speech": "Présentation",
    "marketing": "Plan marketing",
    "market": "Marché", 
    "rentable": "Rentabilité", 
    "risk": "Risque", 
    "tech": "Technologique", 
    "viability": "Viabilité", 
    "durability": "Durabilité",
    "conclusion": "Conclusion"
}

const LABEL = {
    detail:{
        desc: "Brève description du projet",
        sector: "Secteur d'activité",
        aims: "Objectifs du projet"
    },
    market:{
        desc: "Description de la clientèle cible",
        size: "Taille du marché",
        proprieties: "Tendances et opportunités du marché"
    },
    economy:{
        desc: "Description du modèle économique",
        source: "Sources de revenus",
        outlay: "Coûts et dépenses"
    },
    advantage:{
        advantange: "Principaux avantages et différenciateurs",
    },
    state:{
        current: "Étape actuelle du projet",
        realizations: "Réalisations ou jalons déjà atteints"
    },
    other: {
        important: "Tout autre élément à prendre en compte"
    }
}

export default Project;
