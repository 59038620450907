

export const  DfaultStorage = localStorage;

export class DefaultStorage {

    static getItem(item){
        return DfaultStorage.getItem(item);
    }

    static setItem(key, value){
        console.log("set +++++++>", key, value)
        DfaultStorage.setItem(key, value);
        console.log("value setted", localStorage.getItem(key))
    }

    static removeItem(key){
        DfaultStorage.removeItem(key);
    }
}


// KEYS 

export const CREDENTIALS = "MAAPIX-CREDENTIALS";