import { LOGOUT, SET_CREDENTIAL } from "../constants";
import { CREDENTIALS, DefaultStorage } from "../storage";


const credentialReducer = (state={}, action)=>{

    switch (action.type) {
        case SET_CREDENTIAL :{
            return {
                ...state,
                ...action.payload,
            
            }
        }
        case LOGOUT:
            DefaultStorage.removeItem(CREDENTIALS);
            return {};
            
        default:
            return state
    }
}

export default credentialReducer;