import { SET_USER, LOGOUT } from "../constants"
import { setSetting } from "./settings"
import { DefaultStorage } from "../storage"

export const setUser = user =>{
    return {
        type: SET_USER,
        payload: user
    }
}


export const logout = ()=>{
    return async dispatch => {
        await DefaultStorage.removeItem("persist:root")

        // dispatch(clearOrmState());
        
        dispatch(setSetting("openLogout", false));
        
        dispatch({
            type: LOGOUT
        });

        // await Updates.reloadAsync();

        console.log("----- LOGOUT ------")

    }
}





