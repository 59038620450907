import { SET_CREDENTIAL } from "../constants"
import { CREDENTIALS, DefaultStorage } from "../storage";

export const setCredentials = credential=>{
    DefaultStorage.setItem(CREDENTIALS, JSON.stringify(credential));

    return {
        type: SET_CREDENTIAL,
        payload: credential
    }
}

export const loadCredentials = (callback=()=>{})=>{

    return async dispatch=>{
        let credential = DefaultStorage.getItem(CREDENTIALS);
        if(credential){
            credential = JSON.parse(credential);
            dispatch({
                type: SET_CREDENTIAL,
                payload: credential
            })
        }
        callback(credential || {});
    }
}

